import {useFieldArray, Controller} from "react-hook-form";
import {Box, Button, Grid, Paper, TextField, Typography} from "@material-ui/core";

const AppointmentMembers = ({control, register, setValue, getValues, errors}) => {
    const {fields, append, remove, prepend} = useFieldArray({
        control,
        name: "family"
    })

    const defaultMember = {
        first_name: "",
        last_name: "",
        fathers_name: "",
        vpo_number: "",
    }
    return (
        <>
            {fields.map((field, index) => {
                return (
                    <Paper key={field.id}>
                        <Box px={3} py={2} my={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3}>
                                    <Controller
                                        name={`family.${index}.last_name`}
                                        control={control}
                                        render={({field, fieldState: {invalid, error}}) => (
                                            <>
                                                <TextField
                                                    required
                                                    label="Прізвище"
                                                    fullWidth
                                                    margin="dense"
                                                    autoFocus
                                                    {...field}
                                                    error={invalid}
                                                />
                                                <Typography variant="inherit" color="error">
                                                    {invalid && error.message}
                                                </Typography>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Controller
                                        name={`family.${index}.first_name`}
                                        control={control}
                                        render={({field, fieldState: {invalid, error}}) => (
                                            <>
                                                <TextField
                                                    required
                                                    label="Імʼя"
                                                    fullWidth
                                                    margin="dense"
                                                    {...field}
                                                    error={invalid}
                                                />
                                                <Typography variant="inherit" color="error">
                                                    {invalid && error.message}
                                                </Typography>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Controller
                                        name={`family.${index}.fathers_name`}
                                        control={control}
                                        render={({field, fieldState: {invalid, error}}) => (
                                            <>
                                                <TextField
                                                    required
                                                    label="По-батькові"
                                                    fullWidth
                                                    margin="dense"
                                                    {...field}
                                                    error={invalid}
                                                />
                                                <Typography variant="inherit" color="error">
                                                    {invalid && error.message}
                                                </Typography>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Controller
                                        name={`family.${index}.vpo_number`}
                                        control={control}
                                        render={({field, fieldState: {invalid, error}}) => (
                                            <>
                                                <TextField
                                                    required
                                                    label="Номер довідки ВПО"
                                                    fullWidth
                                                    margin="dense"
                                                    {...field}
                                                    error={invalid}
                                                />
                                                <Typography variant="inherit" color="error">
                                                    {invalid && error.message}
                                                </Typography>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="secondary" onClick={() => remove(index)}>
                                        Видалити
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                )
            })}

            {fields.length < 5 ?
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={12}>
                        <Button variant="contained" color="default" onClick={() => {
                            append(defaultMember)
                        }}>
                            Додати
                        </Button>
                    </Grid>
                </Grid> :
                <></>
            }

        </>
    )
}

export default AppointmentMembers