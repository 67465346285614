import axios from 'axios';
import {useState, useContext} from 'react';
import AuthContext from '../../store/auth-context';
import {useHistory} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './login.scss'
import {Col, Container, Row} from "reactstrap";

const Login = ({loginSwitch, setLoginSwitch}) => {
    document.title = 'Login'
    const [email, setEmail] = useState('');
    const [emailTouched, setEmailTouched] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [loading, setLoading] = useState(false);

    const authCtx = useContext(AuthContext);
    const history = useHistory();

    // Email validation
    const emailValid = email.trim() !== '' && email.includes('@');
    const emailInputIsInvalid = !emailValid && emailTouched;
    // Password validation
    const passwordValid = password.trim() !== '' && password.length >= 8;
    const passwordIsInvalid = !passwordValid && passwordTouched;

    let formIsValid = false;

    if (emailValid && passwordValid) {
        formIsValid = true;
    }

    // Input Handler
    const emailHandler = (event) => {
        setEmail(event.target.value);
    };

    const passwordHandler = (e) => {
        setPassword(e.target.value);
    };

    // Blur Handler
    const emailBlurHandler = () => {
        setEmailTouched(true);
    };

    const passwordBlurHandler = () => {
        setPasswordTouched(true);
    };

    // Submission Handler
    const loginHandler = (event) => {
        event.preventDefault();
        setLoading(true);
        const url = `${process.env.REACT_APP_API_ENDPOINT}/signin`
        // const url = 'https://wicked-asylum-93223.herokuapp.com/api/signin'
        axios
            .post(url, {
                email: email,
                password: password,
            })
            .then((res) => {
                setLoading(false);
                const token = res.data.tokens['access'];
                authCtx.login(token);
                authCtx.firstNameSetter(res.data.first_name);
                history.replace('/choose-appointment');
            })
            .catch((err) => {
                setLoading(false);
                // alert('Invalid Credentials. Try Again!');
                toast.error('Invalid Credentials. Try Again!', {
                    position: toast.POSITION.TOP_CENTER,
                });
                console.log(err);
            });
        // setEmailTouched(true);

        // if (!emailValid) {
        //   return;
        // }
        // console.log(email);
        // setEmail('');
        // setEmailTouched(false);
    };

    // Conditionally setting classname for making the form interactive via styling
    const emailInputClass = emailInputIsInvalid
        ? 'form-email invalid'
        : 'form-email';

    const passwordInputClass = passwordIsInvalid
        ? 'form-pwd invalid'
        : 'form-pwd';

    return (
        <>
            <Container style={{marginTop: "20vh"}}>
                <Row>
                    <Col style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <h2>Login</h2>

                        <form onSubmit={loginHandler}>
                            <label htmlFor="email">Email*</label>
                            <br/>
                            <input
                                type="text"
                                name="email"
                                placeholder="mail@website.com"
                                value={email}
                                onChange={emailHandler}
                                onBlur={emailBlurHandler}
                                className={emailInputClass}
                            />
                            <br/>
                            <label htmlFor="password">Password*</label>
                            <br/>
                            <input
                                type="password"
                                name="password"
                                placeholder="Min. 8 character"
                                value={password}
                                onChange={passwordHandler}
                                onBlur={passwordBlurHandler}
                                className={passwordInputClass}
                            />
                            <br/>
                            <button type="submit" className="submitBtn" disabled={!formIsValid}>
                                {!loading ? 'Login' : 'Loading...'}
                            </button>
                        </form>
                        <ToastContainer autoClose={2000}/>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Login;
