import {useContext, useEffect} from "react";
import appointmentService from "../services/appointmentService";
import {useParams} from "react-router";
import {Button, Space, Table} from "antd";
import {Link} from "react-router-dom";
import PrintComponent from "../utilities/Print";
import {Box, Grid} from "@material-ui/core";
import AuthContext from "../../store/auth-context";
import refugeeCategories from "../services/refugeeCategories";

const ViewDailyAppointments = ({dailyAppointments, setDailyAppointments}) => {
    const appDate = useParams().appointment_date
    const authCtx = useContext(AuthContext)
    document.title = `Записи переселенців на ${appDate}`

    useEffect(() => {
        appointmentService
            .getAppointmentList(appointmentService.getHeaders(authCtx), appDate)
            .then(appList => setDailyAppointments(appList))
    }, [setDailyAppointments])

    const columns = [
        {
            title: '№ в черзі',
            dataIndex: 'queue_number',
            key: 'queue_number',
        },
        {
            title: 'Час',
            dataIndex: 'time',
            key: 'time'
        },
        {
            title: 'ПІБ',
            dataIndex: 'fio',
            key: 'fio'
        },
        {
            title: 'Категорія',
            dataIndex: 'category',
            key: 'category'
        },
        {
            title: 'Кількість людей в сімʼї',
            dataIndex: 'family',
            key: 'family'
        },
    ]

    const data = dailyAppointments.map((appData, index) => {
        return (
            {
                fio: appData.last_name + ' ' + appData.first_name + ' ' + appData.fathers_name,
                family: appData?.family?.length,
                queue_number: index + 1,
                key: appData.id,
                time: appData.time_public,
                category: refugeeCategories.getNameByKey(appData.category)
            }
        )
    })

    return (
        <div>
            <Box px={2}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={6}>
                        <h3 style={{paddingLeft: '1.5%', paddingTop: '1%'}}>
                            Записи ВПО на {appDate}
                        </h3>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: "right"}}>
                        <PrintComponent appointments={dailyAppointments} date={appDate}/>
                    </Grid>
                </Grid>
            </Box>

            <Table
                style={{padding: '1%'}}
                dataSource={data}
                columns={columns}
                pagination={false}
            />
        </div>
    )
}

export default ViewDailyAppointments