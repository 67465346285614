import axios from "axios";
const baseUrl = `${process.env.REACT_APP_API_ENDPOINT}/appointments-local/`

const getHeaders = (ctx) => {
    return {
        headers: {
            Authorization: `JWT ${ctx.token}`
        }
    }
}

const getAll = (headers) => {
    const request = axios.get(baseUrl, headers)
    return request.then(response => response.data)
}

const getAppointmentList = (headers, appointmentDate) => {
    const request = axios.get(`${baseUrl}${appointmentDate}`, headers)
    return request.then(response => response.data)
}

const createAppointment = (newAppointment) => {
    const request = axios.post(`${baseUrl}create`, newAppointment)
    return request.then(response => response.data)
}

const getAppointmentStatus = () => {
    const request = axios.get(`${baseUrl}check`)
    return request.then(response => response.data)
}

const search = (headers, s) => {
    const request = axios.get(`${baseUrl}search?s=${s}`, headers)
    return request.then(response => response.data)
}

const localAppointmentService = {
    getHeaders,
    getAll,
    createAppointment,
    getAppointmentList,
    getAppointmentStatus,
    search
}

export default localAppointmentService