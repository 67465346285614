import React from 'react';
import './index.css';
import ReactDOM from 'react-dom'
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
import {AuthContextProvider} from "./store/auth-context";

ReactDOM.render(
    <React.StrictMode>
        <AuthContextProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

reportWebVitals()