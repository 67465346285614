import {useContext, useState} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import "./App.scss"
import AppointmentTable from "./components/screens/AppointmentTable";
import 'antd/dist/antd.css'
import ViewDailyAppointments from "./components/screens/ViewDailyAppointments";
import ViewAppointmentDetail from "./components/screens/ViewAppointmentDetail";
import LoginPage from "./components/auth/login";
import AuthContext from "./store/auth-context";
import NewRefugeeAppointment from "./components/screens/NewRefugeeAppointment";
import ChooseAppointment from "./components/screens/ChooseAppointment";
import NewLocalAppointment from "./components/screens/NewLocalAppointment";
import AdminChooseAppointment from "./components/screens/AdminChooseAppointment";
import LocalAppointmentService from "./components/services/localAppointmentService";
import LocalAppointmentTable from "./components/screens/LocalAppointmentTable";
import ViewDailyLocalAppointments from "./components/screens/ViewDailyLocalAppointments";
import SearchLocal from "./components/screens/SearchLocal";

const App = () => {
    const [appointments, setAppointments] = useState([])
    const [appointmentItems, setAppointmentItems] = useState([])

    const authCtx = useContext(AuthContext)
    const isLoggedIn = authCtx.isLoggedIn
    console.log("is logged in", isLoggedIn)

    return (
        <Router>
            <Switch>
                <Route exact path='/'>
                    <Redirect to="/register"/>
                </Route>
                <Route path='/login'>
                    {!isLoggedIn && <LoginPage/>}
                    {isLoggedIn && <Redirect to='/choose-appointment'/>}
                </Route>
                <Route exact path='/choose-appointment'>
                    {isLoggedIn && <AdminChooseAppointment/>}
                </Route>
                <Route exact path='/refugees'>
                    {isLoggedIn && <AppointmentTable appointments={appointments} setAppointments={setAppointments}/>}
                    {!isLoggedIn && <Redirect to="/login" />}
                </Route>
                <Route exact path='/refugees/view/:appointment_date'>
                    {isLoggedIn && <ViewDailyAppointments dailyAppointments={appointmentItems}
                                           setDailyAppointments={setAppointmentItems}/>}
                </Route>

                <Route exact path='/locals'>
                    {isLoggedIn && <LocalAppointmentTable appointments={appointments} setAppointments={setAppointments}/>}
                    {!isLoggedIn && <Redirect to="/login" />}
                </Route>
                 <Route exact path='/locals/view/:appointment_date'>
                    {isLoggedIn && <ViewDailyLocalAppointments dailyAppointments={appointmentItems}
                                           setDailyAppointments={setAppointmentItems}/>}
                </Route>
                <Route exact path='/locals/search'>
                    {isLoggedIn &&<SearchLocal/>}
                </Route>


                <Route exact path='/register'>
                    <ChooseAppointment/>
                </Route>
                <Route exact path='/register/refugee'>
                    <NewRefugeeAppointment/>
                </Route>
                <Route exact path='/register/local'>
                    <NewLocalAppointment/>
                </Route>
                <Route exact path='/appointments/edit/:appointment_id'>
                    {isLoggedIn && <ViewAppointmentDetail/>}
                </Route>
            </Switch>
        </Router>
    )
}

export default App;
