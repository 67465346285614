import {Button, Space, Table} from "antd";
import {useEffect} from "react";
import appointmentService from "../services/appointmentService";
import {Link} from "react-router-dom";
import AuthContext from "../../store/auth-context";
import {useContext} from "react";

const AppointmentTable = ({appointments, setAppointments}) => {
    document.title = 'Дати запису переселенців'
    const authCtx = useContext(AuthContext)

    useEffect(() => {
        appointmentService
            .getAll(appointmentService.getHeaders(authCtx))
            .then(apps => setAppointments(apps))
    }, [setAppointments])

    const columns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'appointment_date'
        },
        {
            title: 'Усього',
            dataIndex: 'total',
            key: 'total'
        },
        {
            title: 'Опції',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record, index) => (
                <Space>
                    <Link to={`/refugees/view/${record.date}`}>
                        <Button type='primary'>
                            Дивитися
                        </Button>
                    </Link>
                </Space>
            )
        }
    ]

    const data = appointments.map(app => {
        return (
            {
                date: app.date,
                total: app.total,
                key: app.date
            }
        )
    })

    return (
        <div>
            <div style={{display: 'flex'}}>
                <h2 style={{paddingLeft: '1.5%', paddingTop: '1%'}}>
                    Записи ВПО
                </h2>
                <div style={{marginLeft: 'auto', paddingRight: '1%', paddingTop: '1%'}}>
            </div>
            </div>
            <Table
                style={{padding: '1%'}}
                dataSource={data}
                columns={columns}
                pagination={false}
            />
        </div>
    )
}

export default AppointmentTable