import {Box, Button, Grid, Typography} from "@material-ui/core";
import {Link, useLocation} from "react-router-dom";
import logo from "../../images/logo.png"

const ChooseAppointment = () => {
    const {pathname} = useLocation()
    document.title = 'БО БФ “Ранок Відродження”'
    // TODO change this name 5.01.2024 to unlock registrations
    const status = 'vacation'

    if (status === 'vacations') {
        return (
            <Box py={5}>
                <Grid container alignItems="center" justifyContent="center"
                      style={{textAlign: "center", flexDirection: "column"}}>
                    <Box>
                        <img src={logo} style={{width: "70%", marginBottom: "20px"}}/>
                    </Box>
                    <Typography variant="body" component="p" style={{fontSize: "20px", padding: "0 10px"}}>
                        Любі друзі, БО БФ Ранок Відродження зачинений на ремонт з 16 квітня до 1-го травня.<br/> 
                        Допомога тимчасово не надається<br/><br/>   
                    </Typography>
                </Grid>
            </Box>
        )
    }

    return (
        <Box py={2}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Box textAlign="center">
                        <img src={logo} width="70%" style={{maxWidth: "400px"}} alt="new-dawn"/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box textAlign="center" fontSize="20px" py={1}>
                        Виберіть категорію:
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box py={1} textAlign="center">
                        <Link to={{pathname: '/register/refugee', state: {from: pathname}}}>
                            <Button variant="contained" color="primary" style={{minWidth: "200px"}}>
                                ВПО
                            </Button>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box py={1} textAlign="center">
                        <Link to={{pathname: '/register/local', state: {from: pathname}}}>
                            <Button variant="contained" color="primary" style={{minWidth: "200px", textDecoration: "none"}}>
                                Одесит
                            </Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ChooseAppointment