import {Box, Grid} from "@material-ui/core";
import Search from "antd/lib/input/Search";
import localAppointmentService from "../services/localAppointmentService";
import {Button, Table} from "antd";
import {useContext, useState} from "react";
import localCategories from "../services/localCategories";
import appointmentService from "../services/appointmentService";
import AuthContext from "../../store/auth-context";
import {Link} from "react-router-dom";

const SearchLocal = () => {
    document.title = 'Пошук одеситів'
    const [searchResult, setSearchResult] = useState([])
    const authCtx = useContext(AuthContext)

    const columns = [
        {
            title: 'ПІБ',
            dataIndex: 'fio',
            key: 'fio',
        },
        {
            title: 'ІПН',
            dataIndex: 'vat',
            key: 'vat',
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Категорія',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Дата та час запису',
            dataIndex: 'time',
            key: 'time',
        },
    ]

    const onSearch = (searchText) => {
        localAppointmentService.search(appointmentService.getHeaders(authCtx), searchText).then(resp => {
            setSearchResult(resp.map((res, index) => {
                const highlight = (text) => {
                    const regex = new RegExp(searchText, 'gi');
                    const style = {
                        padding: 0,
                        backgroundColor: '#FAEF5D'
                    }
                    const newText = text.replace(regex, (match) => `<mark style="padding: ${style.padding}; background-color: ${style.backgroundColor}">${match}</mark>`);
                    return <span dangerouslySetInnerHTML={{__html: newText}}/>;
                }

                return (
                    {
                        fio: highlight(`${res.last_name} ${res.first_name} ${res.fathers_name}`),
                        key: res.id,
                        time: `${res.date_public} ${res.time_public}`,
                        phone: highlight(`${res.phone}`),
                        vat: highlight(res.vat_number),
                        category: localCategories.getNameByKey(res.category)
                    }
                )
            }))
        })
    };

    return (
        <div>
            <Box py={3}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={3}>
                        <Box px={2}>
                            <Link to={`/locals`}>
                                <Button type='primary'>
                                    Назад
                                </Button>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Search
                            onSearch={onSearch}
                            placeholder="Введіть запрос та натисніть Enter або іконку з лупою"
                            allowClear
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
                <Table
                    style={{padding: '1%'}}
                    dataSource={searchResult}
                    columns={columns}
                    pagination={false}
                    locale={{emptyText: 'Немає результатів пошуку'}}
                />
            </Box>
        </div>
    )
}

export default SearchLocal