import {Fragment} from "react";
import {Box, Grid, Typography, Card, Link, Button} from "@material-ui/core";
import {green} from "@material-ui/core/colors";
import './NewAppointment.css'

const SuccessRegistration = ({date, time}) => {

    return (
        <Fragment>
            <Box px={4} py={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" align="center" style={{color: green[500]}}>
                            Вітаємо!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="justify" style={{marginBottom: 10}}>
                            Ви зареєструвались на отримання допомоги у благодійному фонді “Ранок Відродження”.<br/>
                        </Typography>
                        <Box py={2}>
                            <Typography className="success-result-row">
                                Дата запису: <strong className="blink-success">{date}</strong>
                            </Typography>
                            <Typography className="success-result-row">
                                Час отримання: <strong className="blink-success">{time}</strong>
                            </Typography>
                        </Box>
                        <Typography style={{marginBottom: 10}} align="justify">
                            Для отримання благодійної допомоги у зазначені вище дату та час потрібно прийти до центру за
                            адресою
                            Проспект Гагарина, 8 та зареєструватись у працівника фонду<br/><br/>
                            При собі обовʼязково мати оригінали паспорту, коду ІПН та довідки ВПО з вологою печаткою або
                            особиста Дія. Скріншоти та фото <strong>Не підходять.</strong><br/> <br/>
                        </Typography>
                        <Box>
                            <Typography style={{marginBottom: 30}} className="success-result-row">
                                Як дістатися:
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    href="https://goo.gl/maps/Lmz4h5KoqQwhwttVA"
                                    className="action-button"
                                    target="_blank"
                                >
                                    Прокласти маршрут
                                </Button>
                            </Typography>
                            <Typography style={{marginBottom: 30}} className="success-result-row">
                                Телефон:
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    href="tel:+380996350810"
                                    className="action-button"
                                >
                                    Зателефонувати
                                </Button>
                            </Typography>
                            <Typography style={{marginBottom: 30}} className="success-result-row">
                                ТГ канал:
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    href="https://t.me/pryhystocvokoe"
                                    className="action-button"
                                    target="_blank"
                                >
                                    Підписатися
                                </Button>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    )
}
export default SuccessRegistration