class RefugeeCategories extends Map {
    getForSelect() {
        return Array.from(this.entries()).map(([value, text]) => ({ value, text }));
    }

    getNameByKey(key) {
        return this.get(key) ?? 'Помилка категорії';
    }
}

const refugeeCategories = new RefugeeCategories([
    ["first", "ВПО вперше"],
    ["old", "ВПО 75+"],
    ["disabled", "ВПО 1 гр. інвалідності"]
]);

export default refugeeCategories;