import {useParams} from "react-router";
import appointmentService from "../services/appointmentService";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../store/auth-context";

const ViewAppointmentDetail = () => {
    document.title = 'View Appointment Details'
    const appId = useParams().appointment_id
    const authCtx = useContext(AuthContext)
    const [appointmentDetails, setAppointmentDetails] = useState({
        first_name: '',
        last_name: '',
        fathers_name: '',
        vat_number: '',
        phone: '',
        vpo_number: '',
        family: []
    })

    useEffect(() => {
        appointmentService
            .getAppointmentDetails(appointmentService.getHeaders(authCtx), appId)
            .then(appDetails => setAppointmentDetails(appDetails))
    }, [appId])

    const FIO = `${appointmentDetails.last_name} ${appointmentDetails.first_name} ${appointmentDetails.fathers_name}`

    const members = () => {
        return (
            <>
                {appointmentDetails.family.map(({first_name, last_name, fathers_name, vpo_number}) => (
                    <>
                        <h6>First name: {first_name}</h6>
                        <h6>Last name: {last_name}</h6>
                        <h6>Fathers name: {fathers_name}</h6>
                        <h6>VPO number: {vpo_number}</h6>
                    </>
                ))}
            </>
        )
    }

    return (
        <div>
            <p>
                <strong>FIO:</strong> {FIO}
            </p>
            <p>
                <strong>VPO number:</strong> {appointmentDetails.vpo_number}
            </p>
            <p>
                <strong>VAT:</strong> {appointmentDetails.vat_number}
            </p>
            <p>
                <strong>Phone:</strong> {appointmentDetails.phone}
            </p>
            <p>
                <strong>Family</strong>
                {members()}
            </p>
        </div>
    )
}

export default ViewAppointmentDetail