import {Button, Col, Grid, Row, Space} from "antd";
import {Link} from "react-router-dom";

const AdminChooseAppointment = () => {
    document.title = 'Вибір категорії'
    return (
        <>
            <Row>
                <Col span={24} style={{textAlign: "center", padding: "10px", fontSize: "26px"}}>
                    Виберіть категорію:
                </Col>
            </Row>
            <Row>
                <Col span={12} style={{textAlign: "center"}}>
                    <Link to={`/refugees`}>
                        <Button type='primary' style={{minWidth: "200px"}}>ВПО</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign: "center"}}>
                    <Link to={`/locals`}>
                        <Button type='primary' style={{minWidth: "200px"}}>Одесити</Button>
                    </Link>
                </Col>
            </Row>
        </>
    )
}

export default AdminChooseAppointment