import React, {useRef} from "react";
import ReactToPrint from "react-to-print";
import {
    Box,
    Button,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from "@material-ui/core"
import refugeeCategories from "../services/refugeeCategories";

export default function PrintComponent({appointments, date}) {
    let componentRef = useRef();
    console.log(appointments)
    const useStyles = makeStyles({
        table: {
            pageBreakAfter: "auto"
        },
        th: {
            display: "table-header-group"
        },
        tr: {
            pageBreakInside: "avoid",
            pageBreakAfter: "auto"
        }
    });

    const classes = useStyles();

    return (
        <>
            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <Button variant="contained" color="primary">Друкувати</Button>}
                    content={() => componentRef}
                />

                {/* component to be printed */}
                <div style={{display: "none"}}>
                    <Box py={3} px={2} >
                        <Grid container spacing={2} ref={(el) => (componentRef = el)}>
                            <Grid item lg={12} xs={12} style={{textAlign: "center"}}>
                                <h2>Переселенці на {date}</h2>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead className={classes.th}>
                                            <TableRow className={classes.tr}>
                                                <TableCell>#</TableCell>
                                                <TableCell>Час</TableCell>
                                                <TableCell>ПІБ</TableCell>
                                                <TableCell align="left">№ ВПО</TableCell>
                                                <TableCell align="left">ІПН</TableCell>
                                                <TableCell align="left">Телефон</TableCell>
                                                <TableCell align="left">Категорія</TableCell>
                                                <TableCell align="left">Сімʼя</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {appointments.map((app, index) => (
                                                <TableRow key={app.id} className={classes.tr}>
                                                    <TableCell>{index+1}</TableCell>
                                                    <TableCell>{app.time_public}</TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {app.last_name} {app.first_name} {app.fathers_name}
                                                    </TableCell>
                                                    <TableCell align="left">{app.vpo_number}</TableCell>
                                                    <TableCell align="left">{app.vat_number}</TableCell>
                                                    <TableCell align="left">{app.phone}</TableCell>
                                                    <TableCell align="left">{refugeeCategories.getNameByKey(app.category)}</TableCell>
                                                    <TableCell align="left">{app?.family?.length}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        </>
    );
}